/*!

####Some of this content was sourced from Creative Tim under the below license####

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image";

// reactstrap components
import { Button, Container } from "reactstrap";

// core components

function HomePageHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div
        // style={{
        //   backgroundImage:
        //     "url(" + require("images/neighborhood.jpg").default + ")",
        // }}
        
        className="page-header home-page-header"
        // className="page-header page-header-small"
        data-parallax={true}
        ref={pageHeader}
      >
        <StaticImage
          src="../images/neighborhood.jpg"
          alt=""
          placeholder="blurred"
          style={{
            position: "absolute",
            width: "100%",
            height: "100%"
          }}
        />
        <div className="filter" />
        <Container>
          <div className="motto text-center">
            <h1>Welcome to Elkhorn Highland Ridge!</h1>
            <h3>Great neighbors making great memories.</h3>
            {/* <br />
            <Button tag={Link} to="/welcome"className="btn-round" color="neutral" type="button" outline>
              View Welcome Message
            </Button> */}
          </div>
        </Container>
      </div>
    </>
  );
}

export default HomePageHeader;

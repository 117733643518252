import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Alert,
} from "reactstrap";

// core components
import HomePageHeader from "../components/homePageHeader.js";

// icons
import NextdoorIcon from "../images/svg/nextdoor.svg";
import FacebookIcon from "../images/svg/facebook.svg";

const IndexPage = () => {
  const [alert, setAlert] = React.useState(true);
  const [alertDos, setAlertDos] = React.useState(true);
  
  return (
    <>
      <Layout pageTitle="Home Page">
        <HomePageHeader />

        {/* <Alert className="alert-with-icon home-alert" color="info" isOpen={alert}>
          <Container>
            <div className="alert-wrapper">
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
                onClick={() => setAlert(false)}
              >
                <i className="nc-icon nc-simple-remove" />
              </button>
              <div className="message">
                <Row className="align-items-center">
                  <Col md="6">
                    <div>
                      <StaticImage
                        src="../images/July4th.jpg"
                        alt="4th of July"
                        className="img-rounded img-responsive"
                        placeholder="blurred"
                      />
                    </div>
                  </Col>

                  <Col md="6">
                    <h3>2024 Elkhorn Highland Ridge Independence Day Parade and Activites!</h3>
                    <p>Celebrate Independence Day with the Elkhorn Highland Ridge community on Saturday, June 29th! Join our colorful parade by decorating your bike, car, stroller, or wagon and showing off your patriotic flair. Whether you walk, ride, or roll, everyone can be part of the fun! After the parade, stick around for tasty food trucks, free face painting, and cool off with snow cones (free for the first 60 people!). Don’t miss this chance to march in the parade and enjoy a day packed with festivities and neighborhood spirit. See you there!
                    </p><br />
                    <p>Find more details and RSVP {' '}
                      <a className="alert-link" href="https://docs.google.com/forms/d/e/1FAIpQLSdIcBh7sYGDFECFDvLjEcw_g-5RkezJxLt8V0ynAivcVnH6nA/viewform">
                        here
                      </a>
                      {' '}to join in on the fun!
                    </p>
                    <p>P.S. Please <b>SHARE</b> this with your neighbors!</p>
                  </Col>
                </Row>

              </div>
            </div>
          </Container>
        </Alert> */}
        {/* <Alert className="alert-with-icon home-alert" color="danger" isOpen={alertDos}>
          <Container>
            <div className="alert-wrapper">
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
                onClick={() => setAlertDos(false)}
              >
                <i className="nc-icon nc-simple-remove" />
              </button>
              <div className="message">
                <i className="nc-icon nc-bell-55" /> This year's annual homeowner's meeting is coming up on October 8th at 2 P.M.
                The meeting will be held at the Holiday Inn Express located at 19040 Evans St.  Find the agenda <Link to="/events"><b>here</b></Link>.
              </div>
            </div>
          </Container>
        </Alert> */}
        <div className="main">
          <div className="section section-tight-bottom">
            <Container>
              <Row className="align-items-center">
                <Col md="6">
                  <h2 className="text-primary">A Little Bit About Us</h2>
                  <h5 className="description lead">Elkhorn Highland Ridge is a thriving northwest Omaha neighborhood consisting of 248 single dwelling lots nearest to 192nd and West Maple.</h5>
                  <p>The SID, formed in 2004, was converted to the Elkhorn Highland Ridge Home Owners Association in 2020 at the time of Omaha annexation.</p>
                  <p>We are located in the Elkhorn school district. Our children attend Hillrise Elementary, Elkhorn Middle, and Elkhorn High Schools.</p>
                  <p>Elkhorn Highland Ridge is conveniently close to shopping, restaurants, and only minutes away from outdoor spots like Flanagan Lake with five miles of hiking/biking trails, park, and ample green space.</p>
                </Col>
                <Col md="6">
                  {/* <h4 className="images-title">Rounded Image</h4> */}
                  {/* <img
                    alt="..."
                    className="img-rounded img-responsive"
                    src={require("images/ehr.jpg").default}
                  /> */}
                  <StaticImage
                    src="../images/ehr.jpg"
                    alt="Elkhorn Highland Ridge Neighborhood Sign"
                    className="img-rounded img-responsive"
                    placeholder="blurred"
                  />
                </Col>
              </Row>
            </Container>
          </div>
          <div className="section section-tight section-gray text-center">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <h2 className="text-primary">Now Tell Us About You</h2>
                  <h5 className="description">Join us on social media, indroduce yourself, and start getting to know all your friendly and helpful neighbors.</h5>
                  <Button
                    href="https://www.facebook.com/groups/201047716712224/"
                    key="facebook"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="facebook"
                    className="btn-link"
                  >
                    <FacebookIcon style={{ height: '22px', width: '22px', margin: '5px' }} />
                    Facebook
                  </Button>
                  <Button
                      href="https://nextdoor.com/neighborhood/elkhornhighlandridge--elkhorn--ne/"
                      key="nexdtoor"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="nextdoor"
                      className="btn-link"
                  >
                    <NextdoorIcon style={{ height: '22px', width: '22px', margin: '5px' }}/>
                    Nextdoor
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="section section-tight-top text-center">
            <Container>
              <Row>
                <Col>
                  <div className="embed-responsive embed-responsive-4by3">
                    <iframe loading="lazy" title="Elkhorn Highland Ridge Map" className="embed-responsive-item" src="https://www.google.com/maps/d/u/1/embed?mid=1D4NiVb7pjV2cEqM1CxmcV80pqct0P4-z&ll=41.28685314090232%2C-96.21319711941523&z=14"></iframe>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          
        </div>
      </Layout>
    </>
  )
}

export default IndexPage